import firebase from 'firebase';
import "firebase/storage";

const config = {
    apiKey: "AIzaSyCDWYFSjtO3GBnbeAX34FxkyykH5bsn6-0",
    authDomain: "farmaciacarupano-11932.firebaseapp.com",
    databaseURL: "https://farmaciacarupano-11932.firebaseio.com",
    projectId: "farmaciacarupano-11932",
    storageBucket: "farmaciacarupano-11932.appspot.com",
    messagingSenderId: "34090834655",
};

firebase.initializeApp(config);

const storage = firebase.storage();
   
export { storage, firebase as default};
