import React, { Component } from 'react';
// import AOS from 'aos';
import Nav from './components/nav';
import firebase from './components/Firebase/firebase';
import { storage } from './components/Firebase/firebase';

class App extends Component {
  
  state = {
    homeIsVisible: false,
    medicinesIsVisible: false,
    logInIsVisible: false,
    medicinesPrepIsVisible: false,
    laboratoryIsVisible: false,
    allMedicines: null,
    currentMedicines: null,
    medicinesPrep: null,
    addMedicineIsVisible: false,
    authenticated: false,
    laboratoryTestType:null,
    laboratoryTests:null,
    showSuccessMessage: false,
    showWrongPassWordMessage: false,
    showErrorMessage:false,
    showEmailMissingMessage: false,
    showPasswordMissingMessage:false,
    showNameMissing:false,
    showDescriptionMissing:false,
    showImageMissing:false,
    discountList: null,
    exchangeRate: null,
    exchangeRateIsVisible: false
  }

  componentDidMount() {
    // AOS.init({
    //   duration: 2000
    // })
    let that = this;

    var db = firebase.firestore();
    var medicines = db.collection("Medicines");
    
    var laboratoryTests = db.collection("LaboratoryTests");
    var laboratoryTestType = db.collection("LaboratoryTestType");
    var exchangeRate = db.collection("ExchangeRate").doc("U8JqXm8dWYZiEX7h3djO");
    exchangeRate.get().then(function(doc) {
      if (doc.exists) {
        that.setState({
          exchangeRate: doc.data().ExchangeRate
        })
      } 
      else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
      }).catch(function(error) {
        console.log("Error getting document:", error);
      });

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        that.setState({
          authenticated: true,
        });
      }
    });

    if (this.props.homeIsVisible === true) {
      this.setState({
        homeIsVisible: this.props.homeIsVisible,
      });
    }

    if (this.props.exchangeRateIsVisible === true) {
      this.setState({
        exchangeRateIsVisible: this.props.exchangeRateIsVisible,
      });
    }

    if (this.props.addMedicineIsVisible === true) {
      this.setState({
        addMedicineIsVisible: this.props.addMedicineIsVisible,
      });
    }

    if (this.props.medicinesIsVisible === true) {
      this.loadDiscountList();
      medicines.get().then((meds) => {
        const tempMeds = meds.docs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
        this.setState({
          medicinesIsVisible: this.props.medicinesIsVisible,
          allMedicines: tempMeds,
          currentMedicines: tempMeds
        });
      })
    }

    if (this.props.logInIsVisible === true) {
      this.setState({
        logInIsVisible: this.props.logInIsVisible,
      });
    }

    if (this.props.medicinesPrepIsVisible === true) {
      this.loadDiscountList();
      var medicinesPrep = medicines.where("CategoryId", "==", "rimlUvITDNLujZfCMNpM")
      medicinesPrep.get().then((meds) => {
        const tempMeds = meds.docs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
        this.setState({
          medicinesPrepIsVisible: this.props.medicinesPrepIsVisible,
          medicinesPrep: tempMeds
        });
      })
    }

    if (this.props.laboratoryIsVisible === true) {
      laboratoryTestType.get().then((testTypes) => {
        const tempLaboratoryTestType = testTypes.docs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
        laboratoryTests.get().then((meds) => {
          const tempLaboratoryTests = meds.docs.map((doc) => {
            return { id: doc.id, ...doc.data() }
          })
          this.setState({
            laboratoryIsVisible: this.props.laboratoryIsVisible,
            laboratoryTestType: tempLaboratoryTestType,
            laboratoryTests: tempLaboratoryTests
          });
        })
      })
    }
  }

  handleChange = (e) => {
    let allMedicines = [];
    let filteredMedicines = [];

    if (e.target.value !== "") {
      allMedicines = this.state.allMedicines;

      filteredMedicines = allMedicines.filter(item => {
        const lc = item.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const filter = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        return lc.includes(filter);
      });
    } else {
      filteredMedicines = this.state.allMedicines;
    }
    this.setState({
      currentMedicines: filteredMedicines
    });
  }

  handleNameChange = (e) => {
    let newMedicineName = "";
    if (e.target.value !== "") {
      newMedicineName = e.target.value;
    }
    this.setState({
      newMedicineName: newMedicineName
    });
  }

  handleDescriptionChange = (e) => {
    let newMedicineDescription = "";
    if (e.target.value !== "") {
      newMedicineDescription = e.target.value;
    }
    this.setState({
      newMedicineDescription: newMedicineDescription
    });
  }

  handleInputChange = (e) => {
    if (e.target.files[0]) {
      this.setState({
        newMedicineImage: e.target.files[0],
        newMedicineUrl: URL.createObjectURL(e.target.files[0])
      });
    }
  }

  loadDiscountList = () => {
    var db = firebase.firestore();
    var discountList = db.collection("Discount");

    discountList.get().then((discountList) => {
      const tempList = discountList.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
      tempList.push({id:"discountless",Name:"eliminar descuento"})
      tempList.sort(function(a, b) {
        return a.Name.localeCompare(b.Name)
      });
      this.setState({
        discountList: tempList
      });
    })
  }

  deleteMedicine = (id) => {
    var db = firebase.firestore();
    db.collection("Medicines").doc(id).delete().then(function () {
      console.log("Document successfully deleted!");
      window.location.reload(true);
    }).catch(function (error) {
      console.error("Error removing document: ", error);
    });
  }

  saveMedicine = (id, name, description, image, discountId, price) => {
    var db = firebase.firestore();
    var that = this;

    if(!name){
      this.setState({
        showNameMissing: true
      })
      return
    }

    if(!description){
      this.setState({
        showDescriptionMissing: true
      })
      return
    }

    if(!image){
      this.setState({
        showImageMissing: true
      })
      return
    }

    if (typeof image === 'string') {
      if(id){
        db.collection("Medicines").doc(id).set({
          Name: name,
          Description: description,
          ImgUrl: image,
          DiscountId: discountId,
          Price: price
        })
        .then(function () {
          console.log("Document successfully written!");
          window.location.reload(true);
        })
        .catch(function (error) {
          console.log("Error writting document: ", error);
          that.setState({
            showErrorMessage: true
          })
        });
      }
      else{
        db.collection("Medicines").add({
          Name: name,
          Description: description,
          ImgUrl: image,
          DiscountId: discountId,
          Price: price
        })
          .then(function () {
            console.log("Document successfully written!");
            that.setState({
              showSuccessMessage: true
            })
            window.location.reload(true);
          })
          .catch(function (error) {
            console.log("Error writting document: ", error);
            that.setState({
              showErrorMessage: true
            })
          });
      }
    }
    else {
      const uploadTask = storage.ref(`Medicines/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          //progress function
        },
        (error) => {
          //error function
          console.log(error);
        },
        () => {
          //complete function
          storage.ref("Medicines").child(image.name).getDownloadURL().then(imageUrl => {
            if(id){
              db.collection("Medicines").doc(id).set({
                Name: name,
                Description: description,
                ImgUrl: imageUrl,
                DiscountId: discountId,
                Price: price
              })
                .then(function () {
                  console.log("Document successfully written!");
                  window.location.reload(true);
                })
                .catch(function (error) {
                  console.log("Error writting document: ", error);
                  that.setState({
                    showErrorMessage: true
                  })
                });
            }
            else {
              db.collection("Medicines").add({
                Name: name,
                Description: description,
                ImgUrl: imageUrl,
                DiscountId: discountId,
                Price: price
              })
              .then(function () {
                console.log("Document successfully written!");
                that.setState({
                  showSuccessMessage: true
                })
                window.location.reload(true);
              })
              .catch(function (error) {
                console.log("Error writting document: ", error);
                that.setState({
                  showErrorMessage: true
                })
              });
            }
          })
        }
      )
    }
  }

  loginAuth = (email, password) => {
    var that = this;
    if(!email){
      this.setState({
        showEmailMissingMessage:true
      })
      return;
    }

    if(!password){
      this.setState({
        showPasswordMissingMessage:true
      })
      return;
    }

    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(function (user) {
        that.setState({
          authenticated: true,
          showSuccessMessage:true
        });
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        // [START_EXCLUDE]
        if (errorCode === 'auth/wrong-password') {
          that.setState({
            showWrongPassWordMessage:true
          });
        } 
        else if (errorCode === 'auth/user-not-found') {
          that.setState({
            showEmailMissingMessage:true
          });
        }
        else{
            that.setState({
              showErrorMessage:true
            });
          }
      })
  }

  closeSuccessMessage = () => {
    this.setState({
      showSuccessMessage:false
    });
  }

  closeWrongPasswordMessage = () => {
    this.setState({
      showWrongPassWordMessage:false
    });
  }

  closeErrorMessage = () => {
    this.setState({
      showErrorMessage:false
    });
  }

  closeEmailMissingMessage = () => {
    this.setState({
      showEmailMissingMessage:false
    });
  }

  closePasswordMissingMessage = () => {
    this.setState({
      showPasswordMissingMessage:false
    });
  }

  closeNameMissingMessage = () => {
    this.setState({
      showNameMissing:false
    });
  }

  closeDescriptionMissingMessage = () => {
    this.setState({
      showDescriptionMissing:false
    });
  }

  closeImageMissingMessage = () => {
    this.setState({
      showImageMissing:false
    });
  }

  saveExchangeRate = (exchangeRate) => {
    var db = firebase.firestore();
    var that = this;

    db.collection("ExchangeRate").doc("U8JqXm8dWYZiEX7h3djO").set({
      ExchangeRate: exchangeRate,
      Name: "Bolivares"
    })
    .then(function () {
      console.log("Exchange rate succesfully updated!");
      that.setState({
        showSuccessMessage:true
      });
    })
    .catch(function (error) {
      console.log("Error writting document: ", error);
    });
  }
  

  render() {
    return (
      <Nav
        homeIsVisible={this.state.homeIsVisible}
        exchangeRateIsVisible = {this.state.exchangeRateIsVisible}
        medicinesIsVisible={this.state.medicinesIsVisible}
        logInIsVisible={this.state.logInIsVisible}
        medicinesPrepIsVisible={this.state.medicinesPrepIsVisible}
        laboratoryIsVisible={this.state.laboratoryIsVisible}
        currentMedicines={this.state.currentMedicines}
        handleChange={this.handleChange}
        medicinesPrep={this.state.medicinesPrep}
        addMedicineIsVisible={this.state.addMedicineIsVisible}
        saveMedicine={this.saveMedicine}
        deleteMedicine={this.deleteMedicine}
        loginAuth={this.loginAuth}
        authenticated={this.state.authenticated}
        laboratoryTestType = {this.state.laboratoryTestType}
        laboratoryTests = {this.state.laboratoryTests}
        showSuccessMessage = {this.state.showSuccessMessage}
        showWrongPassWordMessage = {this.state.showWrongPassWordMessage}
        showErrorMessage = {this.state.showErrorMessage}
        closeSuccessMessage = {this.closeSuccessMessage}
        closeWrongPasswordMessage = {this.closeWrongPasswordMessage}
        closeErrorMessage = {this.closeErrorMessage}
        showEmailMissingMessage = {this.state.showEmailMissingMessage}
        showPasswordMissingMessage = {this.state.showPasswordMissingMessage}
        closeEmailMissingMessage = {this.closeEmailMissingMessage}
        closePasswordMissingMessage = {this.closePasswordMissingMessage}
        showNameMissing = {this.state.showNameMissing}
        showDescriptionMissing = {this.state.showDescriptionMissing}
        showImageMissing = {this.state.showImageMissing}
        closeNameMissingMessage = {this.closeNameMissingMessage}
        closeDescriptionMissingMessage = {this.closeDescriptionMissingMessage}
        closeImageMissingMessage = {this.closeImageMissingMessage}
        loadDiscountList = {this.loadDiscountList}
        discountList = {this.state.discountList}
        exchangeRate = {this.state.exchangeRate}
        saveExchangeRate = {this.saveExchangeRate}
      />
    )
  }
}

export default App;
