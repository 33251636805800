import React from 'react';
import farmaciaIcon from '../assets/snakeImg.svg';
import Home from './home';
import Medicines from './Medicines/medicines';
import LogIn from './logIn';
import MedicinesPrep from './PreparedMedicines/medicinesPrep';
import Laboratory from './laboratory';
import AddMedicine from './addMedicine';
import Class from './nav.module.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ExchangeRate from './exchangeRate';

const Navigation = (props) => {
    return (
        <div>
            <Navbar sticky="top" bg="light" variant="light" className={Class.fontFamilyNav}>
                <img className={Class.farmaciaIcon} src={farmaciaIcon} alt="Icono farmacia Carupano" />
                <Navbar.Brand className={Class.brandName} href="/">Farmacia Carupano</Navbar.Brand>
                    <Nav className="ml-auto">
                            <Nav.Link href="/" className={Class.navlink}>Inicio</Nav.Link>
                            <Nav.Link href="/medicinas" onClick={props.getMedicines} className={Class.navlink}>Medicinas</Nav.Link>
                            {props.authenticated ? <Nav.Link href="/agregarMedicina" className={Class.navlink}>Agregar Medicina</Nav.Link> : null}
                    </Nav>
            </Navbar>
            {props.homeIsVisible ? <Home /> : null}
            {props.exchangeRateIsVisible ? <ExchangeRate 
                saveExchangeRate = {props.saveExchangeRate} 
                showSuccessMessage = {props.showSuccessMessage}
                closeSuccessMessage = {props.closeSuccessMessage}/> 
                : null}
            {props.medicinesIsVisible ? <Medicines
                currentMedicines={props.currentMedicines}
                handleChange={props.handleChange}
                deleteMedicine={props.deleteMedicine}
                saveMedicine={props.saveMedicine}
                authenticated={props.authenticated}
                loadDiscountList = {props.loadDiscountList}
                discountList = {props.discountList}
                exchangeRate = {props.exchangeRate}
                showNameMissing = {props.showNameMissing}
                showDescriptionMissing = {props.showDescriptionMissing}
                closeNameMissingMessage = {props.closeNameMissingMessage}
                closeDescriptionMissingMessage = {props.closeDescriptionMissingMessage}/>
                : null}
            {props.logInIsVisible ? <LogIn
                loginAuth={props.loginAuth} 
                showSuccessMessage = {props.showSuccessMessage}
                showWrongPassWordMessage = {props.showWrongPassWordMessage}
                showErrorMessage = {props.showErrorMessage}
                closeSuccessMessage = {props.closeSuccessMessage}
                closeWrongPasswordMessage = {props.closeWrongPasswordMessage}
                closeErrorMessage = {props.closeErrorMessage}
                showEmailMissingMessage = {props.showEmailMissingMessage}
                showPasswordMissingMessage = {props.showPasswordMissingMessage}
                closeEmailMissingMessage = {props.closeEmailMissingMessage}
                closePasswordMissingMessage = {props.closePasswordMissingMessage}/>
                : null}
            {props.medicinesPrepIsVisible ? <MedicinesPrep
                medicinesPrep={props.medicinesPrep} 
                loadDiscountList = {props.loadDiscountList}
                discountList = {props.discountList}/> : null}
            {props.laboratoryIsVisible ? <Laboratory
                laboratoryTests={props.laboratoryTests}
                laboratoryTestType={props.laboratoryTestType} />
                : null}
            {props.addMedicineIsVisible ? <AddMedicine
                saveMedicine={props.saveMedicine} 
                showSuccessMessage={props.showSuccessMessage}
                closeSuccessMessage= {props.closeSuccessMessage}
                showErrorMessage = {props.showErrorMessage}
                closeErrorMessage = {props.closeErrorMessage}
                showNameMissing = {props.showNameMissing}
                showDescriptionMissing = {props.showDescriptionMissing}
                showImageMissing = {props.showImageMissing}
                closeNameMissingMessage = {props.closeNameMissingMessage}
                closeDescriptionMissingMessage = {props.closeDescriptionMissingMessage}
                closeImageMissingMessage = {props.closeImageMissingMessage}/>
                : null}
        </div>
    )
}

export default Navigation;