import React from 'react';
import FarmaciaTitle from '../assets/FarmaciaTitle.jpg';
import Class from './home.module.css';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Laboratory from '../assets/laboratoryLQ.jpg';
import Medicine from '../assets/medicineLQ.jpg';
import PreparedMedicine from '../assets/preparedMedicineLQ.jpg';
import ContactImage from '../assets/ContactImage.png';
import MessageImg from '../assets/messageImg.png';
import ClockImg from '../assets/clockImg.png';
import MapImg from '../assets/mapImg.png';

const home = (props) => {
    return (
        <div>
            <div className={Class.containerInicio}>
                <img src={FarmaciaTitle} className={Class.homeImage} alt="background" />
                <div className={Class.textInicio}>
                    <div>Encuentra las Medicinas que Necesitas al Mejor Precio!</div>
                    <div className={Class.slogan}>Servicio de calidad a su alcance</div>
                    <Button className={Class.button} href="/medicinas" variant="contained" color="secondary">Buscar Medicina</Button>
                </div>
            </div>
            <div className={Class.mainContainer}>
                <div className={Class.servicesTitle}>Nuestros servicios</div>
                <div className={`${Class.section} ${Class.group}`}>
                    <div className={`${Class.col} ${Class.span_1_of_3}`}>
                        <Card>
                            <CardActionArea className={Class.action} href="/medicinas">
                                <CardMedia
                                    className={Class.media}
                                    image={Medicine}
                                    title="Medicinas"
                                />
                                <CardContent>
                                    <Typography className={Class.subtitle}>
                                        Medicinas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    <div className={`${Class.col} ${Class.span_1_of_3}`}>
                        <Card>
                            <CardActionArea className={Class.action} href="/medicinasPrep">
                                <CardMedia
                                    className={Class.media}
                                    image={PreparedMedicine}
                                    title="Medicinas preparadas"
                                />
                                <CardContent>
                                    <Typography className={Class.subtitle}>
                                        Medicinas preparadas
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    <div className={`${Class.col} ${Class.span_1_of_3}`}>
                        <Card>
                            <CardActionArea className={Class.action} href="/laboratorio">
                                <CardMedia
                                    className={Class.media}
                                    image={Laboratory}
                                    title="Laboratorio"
                                />
                                <CardContent>
                                    <Typography className={Class.subtitle}>
                                        Laboratorio
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                </div>
            </div>
            <div className={Class.contactContainer}>
                <img src={ContactImage} className={Class.homeImage} alt="background" />
                <div className={Class.fromMobileContact}>
                    <div className={Class.contactTitlePhone}>Contáctanos</div>
                    <br/>
                    <div className={Class.titleDetails}>Info de contacto</div>
                    <a href="tel:+58 0286 9223780">+58 0286 9223780</a>
                    <br/>
                    <div className={Class.titleDetails}>Dirección</div>
                    <div>Avenida principal de castillito,</div>
                    <div>Puerto Ordaz, edo. Bolívar. Venezuela</div>
                </div>
                <div className={Class.contactInfo}>
                    <div className={Class.contactTitle}>Contáctanos</div>
                    <div className={Class.messageImg}>
                        <img src={MessageImg} alt="Email" />
                        <div className={Class.messageText}>
                            <div>Info de contacto</div>
                            <div>farmaciacarupano@hotmail.com</div>
                            <div>+58 0286 9223780</div>
                            <div>+58 0286 9239556</div>
                        </div>
                    </div>
                    <div className={Class.mapImg}>
                        <img src={MapImg} alt="Email" />
                        <div className={Class.mapText}>
                            <div>Dirección</div>
                            <div>Avenida principal de castillito,</div>
                            <div>Puerto Ordaz, edo. Bolívar. Venezuela</div>
                        </div>
                    </div>
                    <div className={Class.clockImg}>
                        <img src={ClockImg} className={Class.contactImages} alt="Clock" />
                        <div className={Class.clockText}>
                            <div>Horario de apertura</div>
                            <div>Lun-Vier: 8am - 3pm</div>
                            <div>Sab: 8am - 1pm</div>
                            <div>Dom: cerrado</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default home;