import React, { useState } from 'react';
import Class from './logIn.module.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ImgHome2 from '../assets/ImgHome2LQ.png';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = (props) => {

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const loginAuth = () => {
        props.loginAuth(email, password)

    };

    const changedEmail = (e) => {
        setEmail(e.target.value);
    }

    const changedPassword = (e) => {
        setPassword(e.target.value);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.closeSuccessMessage();
        props.closeWrongPasswordMessage();
        props.closeErrorMessage();
        props.closeEmailMissingMessage();
        props.closePasswordMissingMessage();
    };

    return (
        <div className={Class.container}>
            <div className={Class.authContainer}>
                <div className={Class.title}>Autenticación</div>
                <br />
                <br />
                <TextField
                    label="Email"
                    variant="outlined"
                    className={Class.textField}
                    onChange={changedEmail}
                />
                <br />
                <br />
                <TextField
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    className={Class.textField}
                    onChange={changedPassword}
                />
                <br />
                <br />
                <Button variant="contained" className={Class.button} onClick={loginAuth} color="secondary">Log in</Button>
            </div>
            <div className={Class.imgContainer}>
                <img src={ImgHome2} className={Class.image} alt="logInImage" />
            </div>
            <Snackbar open={props.showSuccessMessage} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose}  severity="success">
                    Autenticación exitosa!
                </Alert>
            </Snackbar>
            <Snackbar open={props.showWrongPassWordMessage} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Contraseña incorrecta!
                </Alert>
            </Snackbar>
            <Snackbar open={props.showErrorMessage} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Se ha producido un error, intete más tarde!
                </Alert>
            </Snackbar>
            <Snackbar open={props.showEmailMissingMessage} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Introduzca Email valida!
                </Alert>
            </Snackbar>
            <Snackbar open={props.showPasswordMissingMessage} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Introduzca contraseña
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Login;