import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Class from './addMedicine.module.css';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddMedicine = (props) => {
    const [newMedicineImage, setImage] = useState(null);
    const [newMedicineUrl, setImageUrl] = useState(null);
    const [newMedicineName, setName] = useState(null);
    const [newMedicineDescription, setDescription] = useState(null);
    const [newMedicinePrice, setPrice] = useState(null);
    

    const handleInputChange = (e) => {
        if(e.target.files[0]){
            setImage(e.target.files[0]);
            setImageUrl(URL.createObjectURL(e.target.files[0]));
        }
    }

    const handleNameChange = (e) =>{
        let newMedicineName = "";
        if(e.target.value !== "") {
          newMedicineName = e.target.value;
        }
        setName(newMedicineName);
    }
    
    const handleDescriptionChange = (e) => {
        let newMedicineDescription = "";
        if(e.target.value !== "") {
          newMedicineDescription = e.target.value;
        }
        setDescription(newMedicineDescription);
    }

    const saveMedicine = () => {
        props.saveMedicine(null,newMedicineName, newMedicineDescription, newMedicineImage, "", newMedicinePrice)
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.closeSuccessMessage();
        props.closeErrorMessage();
        props.closeNameMissingMessage();
        props.closeDescriptionMissingMessage();
        props.closeImageMissingMessage();
    }

    const handlePriceChange = (e) =>{
        let newMedicinePrice = "";
        if(e.target.value !== "") {
            newMedicinePrice = e.target.value;
        }
        setPrice(newMedicinePrice);
    }

    return (
        <div className={Class.container}>
            <div className={Class.addMedicine}>Agrega Medicina</div>
            <div className={Class.verticalSpace}/>
            <div className={Class.flexContainer}>
                <div className={Class.formContainer}>
                    <form >
                        <div>
                            <TextField
                                label="Nombre"
                                onChange={handleNameChange}
                                variant="outlined"
                                className={Class.textField}
                            />
                        </div>
                        <br/>
                        <div>
                            <TextField
                                label="Descripción"
                                multiline
                                onChange={handleDescriptionChange}
                                variant="outlined"
                                className={Class.textField}
                            />
                        </div>
                        <br/>
                        <div>
                        <TextField
                            label="Precio"
                            id="Precio"
                            className={Class.textFieldPrice}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            variant="outlined"
                            onChange={handlePriceChange}
                        />
                        </div>
                        <br/>
                        <label htmlFor="file" className={Class.selectImg}>Seleziona imagen</label>
                        <input type="file" id="file" className={Class.inputFile} onChange={handleInputChange}/>
                    </form>
                </div>
                <div className={Class.cardContainer}>
                    <Card className={Class.card}>
                        <CardMedia
                            className={Class.media}
                            image={newMedicineUrl}
                            title=""
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {newMedicineName}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {newMedicineDescription}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <div className={Class.verticalSpace}/>
            <div className={Class.saveMedicineContainer}>
                <Button className={Class.button} variant="contained" color="secondary" onClick={saveMedicine}>Guardar</Button>
            </div>
            {/* <Snackbar open={props.showSuccessMessage} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose}  severity="success">
                    Medicina agregada correctamente
                </Alert>
            </Snackbar> */}
            <Snackbar open={props.showErrorMessage} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Se ha producido un error, intete más tarde!
                </Alert>
            </Snackbar>
            <Snackbar open={props.showNameMissing} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose}  severity="warning">
                    Introducir nombre della medicina
                </Alert>
            </Snackbar>
            <Snackbar open={props.showDescriptionMissing} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose}  severity="warning">
                    Introducir descripción della medicina
                </Alert>
            </Snackbar>
            <Snackbar open={props.showImageMissing} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose}  severity="warning">
                    Introducir una imagen della medicina
                </Alert>
            </Snackbar>
        </div>
    )
}

export default AddMedicine;
