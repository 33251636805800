import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Router, Switch, Route} from 'react-router-dom';
import { createBrowserHistory } from 'history';

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,

<Router history={createBrowserHistory()}>
  <Switch>
    <Route 
      exact path='/'
      render = {(props) => <App {...props} homeIsVisible={true}/>}  
    />
    <Route 
      exact path='/medicinas'
      render = {(props) => <App {...props} medicinesIsVisible={true}/>}  
    />
    <Route 
      exact path='/login'
      render = {(props) => <App {...props} logInIsVisible={true}/>}  
    />
    <Route 
      exact path='/tasaCambio'
      render = {(props) => <App {...props} exchangeRateIsVisible={true}/>}  
    />
    <Route 
      exact path='/medicinasPrep'
      render = {(props) => <App {...props} medicinesPrepIsVisible={true}/>}  
    />
    <Route 
      exact path='/laboratorio'
      render = {(props) => <App {...props} laboratoryIsVisible={true}/>}  
    />
    <Route 
      exact path='/agregarMedicina'
      render = {(props) => <App {...props} addMedicineIsVisible={true}/>}  
    />
  </Switch>
</Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
