import React from 'react';
import Medicine from '../Medicines/medicine';
import Grid from '@material-ui/core/Grid';
import Class from './medicinesPrep.module.css';

const medicinesPrep = (props) => {
    return (
        <div className={Class.container}>
            <div className={Class.title}>Medicinas preparadas en Farmacia Carupano</div>
            <div className={Class.verticalSpace}/>
            <Grid container className={Class.grid} spacing={10}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={10}>
                        {props.medicinesPrep.map((medicine, index) => (
                            <Grid key={index} item>
                                <Medicine medicine={medicine} 
                                loadDiscountList = {props.loadDiscountList}
                                discountList = {props.discountList}/>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default medicinesPrep;