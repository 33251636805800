import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Class from './medicine.module.css';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Medicine = (props) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [deleteMedicineMessage, openDeleteMedicineMessage] = useState(false)
  const [discount, setDiscount] = React.useState('');
  const [medicineprice, setPrice] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    setImage(props.medicine.ImgUrl);
    setName(props.medicine.Name);
    setDescription(props.medicine.Description);
    setDiscount(props.medicine.DiscountId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.saveMedicine(props.medicine.id,name, description, image, discount,medicineprice)
    setOpen(false);
  };

  const handleInputChange = (e) => {
    if(e.target.files[0]){
      setImageName(e.target.files[0].name);
      setImage(e.target.files[0])
    }
  }

  const handleNameChange = (e) => {
    let medicineName = "";
        if(e.target.value !== "") {
          medicineName = e.target.value;
        }
    setName(medicineName);
  }

  const handleDescriptionChange = (e) => {
    let medicineDescription = "";
        if(e.target.value !== "") {
          medicineDescription = e.target.value;
        }
    setDescription(medicineDescription);
  }

  const handlePriceChange = (e) => {
    let medicinePrice = "";
      if(e.target.value !== "") {
        medicinePrice = e.target.value;
      }
    setPrice(medicinePrice);
  }

  const deleteMedicine = () => {
    openDeleteMedicineMessage(true)
  }

  const closeDeleteMedicine = () => {
    openDeleteMedicineMessage(false);
  }

  const handleDelete = () => {
    props.deleteMedicine(props.medicine.id);
    openDeleteMedicineMessage(false);
  }

  const changeDiscount = (e) => {
    if(e.target.value === "discountless"){
      setDiscount("");
    }
    else{
      setDiscount(e.target.value);
    }
  }

  const getDiscountName = () => {
    let subList = props.discountList.filter(item => 
      item.id.trim() === props.medicine.DiscountId.trim()
    )
    return (subList.length===0) ? null : subList[0].Name 
  }
  
  const discountName = getDiscountName();

  const priceExist = props.medicine.Price ? true : false;

  const price = props.medicine.Price;

  const priceBs = (parseFloat(props.medicine.Price) * props.exchangeRate).toLocaleString("es-CO").toString();

  const closeMessage = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    props.closeSuccessMessage();
    props.closeErrorMessage();
    props.closeNameMissingMessage();
    props.closeDescriptionMissingMessage();
    props.closeImageMissingMessage();
}

  return (
    <div>
      <Card className={Class.card}>
        {discountName ? 
          <div className={Class.discount}>
            {discountName}
          </div> : null}
        <CardMedia
          className={Class.media}
          image={props.medicine.ImgUrl}
          title={props.medicine.Name}
        />
        <CardContent>
          <Typography className={Class.subtitle} gutterBottom variant="h5" component="h2">
            {props.medicine.Name}
          </Typography>
          {priceExist && props.authenticated ?
            <Typography className={Class.price} variant="subtitle2" gutterBottom>
              Precio: {price} $ - {priceBs} Bs
            </Typography>
            :
            null
          }
          <Typography variant="body2" color="textSecondary" className={Class.description} component="p">
            {props.medicine.Description}
          </Typography>
        </CardContent>
        {props.authenticated ? 
        <CardActions>
          <Button size="small" color="primary" onClick={deleteMedicine}>Elimina</Button>
          <Button size="small" color="primary" onClick={handleClickOpen}>Actualiza</Button>
        </CardActions>
        : null}
      </Card>
      <Dialog onClose={handleClose} open={open}  fullWidth={true} maxWidth={"sm"} className={Class.dialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Actualiza medicina</DialogTitle>
        <DialogContent>
            <TextField
                label="Nombre"
                onChange={handleNameChange}
                variant="outlined"
                className={Class.textField}
                defaultValue = {props.medicine.Name}
            />
            <div className={Class.verticalSpace}/>
            <TextField
                label="Descripción"
                multiline
                onChange={handleDescriptionChange}
                variant="outlined"
                className={Class.textField}
                defaultValue = {props.medicine.Description}
            />
            <div className={Class.verticalSpace}/>
            <TextField
                label="Precio"
                id="Precio"
                className={Class.textFieldPrice}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                variant="outlined"
                onChange={handlePriceChange}
                defaultValue = {props.medicine.Price}
            />
            <div className={Class.verticalSpace}/>
            <InputLabel id="discountLabel">Descuento</InputLabel>
            <Select
              labelId="discountLabel"
              id="discountSelect"
              value={discount}
              onChange={changeDiscount}
              className={Class.selectDiscount}
            >
              {props.discountList.map((discount, index) => (
                <MenuItem value={discount.id} key={index}>{discount.Name}</MenuItem>
              ))}
            </Select>
            <div className={Class.verticalSpace}/>
            <label htmlFor="file" className={Class.selectImg}>Selecciona imagen</label>
            <input type="file" id="file" className={Class.inputFile} onChange={handleInputChange}/>
            <span className={Class.imageName}>{imageName}</span>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancelar
            </Button>
            <Button onClick={handleSave} color="primary">
                Guardar
            </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={closeDeleteMedicine} open={deleteMedicineMessage} className={Class.dialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Elimina medicina</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Eliminar definitivamente {props.medicine.Name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={closeDeleteMedicine} color="primary">
                Cancelar
            </Button>
            <Button onClick={handleDelete} color="primary">
                Eliminar
            </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={props.showNameMissing} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={closeMessage}>
          <Alert onClose={closeMessage}  severity="warning">
              Introducir nombre della medicina
          </Alert>
      </Snackbar>
      <Snackbar open={props.showDescriptionMissing} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={closeMessage}>
          <Alert onClose={closeMessage}  severity="warning">
              Introducir descripción della medicina
          </Alert>
      </Snackbar>
    </div>
  );
}

export default Medicine;