import React from 'react';
import LaboratoryMicroscope from '../assets/laboratoryMicroscope.png';
import Class from './laboratory.module.css';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Laboratory = (props) => {

    const type1 = props.laboratoryTestType.filter(item => {
        return item.id.includes("AG0HAZMzu4Rs7ltsTWq7")
    })

    const type2 = props.laboratoryTestType.filter(item => {
        return item.id.includes("Aya7kPCpK4cQCb2MLtHb")
    })
    const type3 = props.laboratoryTestType.filter(item => {
        return item.id.includes("HK6NTcUc21uo0Sxk2LYd")
    })
    const type4 = props.laboratoryTestType.filter(item => {
        return item.id.includes("XtnhXhm8PUt5lu5gpMQG")
    })
    const type5 = props.laboratoryTestType.filter(item => {
        return item.id.includes("ZvXVsUz2NPsyFoNzSrAX")
    })
    const type6 = props.laboratoryTestType.filter(item => {
        return item.id.includes("sf2J1Fe4LVT7nCQaHDMt")
    })
    const type7 = props.laboratoryTestType.filter(item => {
        return item.id.includes("tLrruqMvcTd4JyxchdUw")
    })
    const type8 = props.laboratoryTestType.filter(item => {
        return item.id.includes("uunFUmgqwmPjrr5jmy7b")
    })

    const test1 = props.laboratoryTests.filter(item => {
        return item.TypeId.includes("AG0HAZMzu4Rs7ltsTWq7")
    })

    const test2 = props.laboratoryTests.filter(item => {
        return item.TypeId.includes("Aya7kPCpK4cQCb2MLtHb")
    })
    const test3 = props.laboratoryTests.filter(item => {
        return item.TypeId.includes("HK6NTcUc21uo0Sxk2LYd")
    })
    const test4 = props.laboratoryTests.filter(item => {
        return item.TypeId.includes("XtnhXhm8PUt5lu5gpMQG")
    })
    const test5 = props.laboratoryTests.filter(item => {
        return item.TypeId.includes("ZvXVsUz2NPsyFoNzSrAX")
    })
    const test6 = props.laboratoryTests.filter(item => {
        return item.TypeId.includes("sf2J1Fe4LVT7nCQaHDMt")
    })
    const test7 = props.laboratoryTests.filter(item => {
        return item.TypeId.includes("tLrruqMvcTd4JyxchdUw")
    })
    const test8 = props.laboratoryTests.filter(item => {
        return item.TypeId.includes("uunFUmgqwmPjrr5jmy7b")
    })

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={Class.laboratoryContainer}>
            <img src={LaboratoryMicroscope} className={Class.LaboratoryMicroscope} alt="LaboratoryMicroscope" />
            <div className={Class.textLaboratory}>Exámenes de laboratorio</div>
            <div className={Class.expasionPanel}>
                <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>{type1[0].Name}</Typography>
                    </ExpansionPanelSummary>
                    {test1.map((test, index) => (
                        <ul>
                            <li><Typography>{test.Name}</Typography></li>
                        </ul>
                    ))}
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>{type2[0].Name}</Typography>
                    </ExpansionPanelSummary>
                    {test2.map((test, index) => (
                        <ul>
                            <li><Typography>{test.Name}</Typography></li>
                        </ul>
                    ))}
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>{type3[0].Name}</Typography>
                    </ExpansionPanelSummary>
                    {test3.map((test, index) => (
                        <ul>
                            <li><Typography>{test.Name}</Typography></li>
                        </ul>
                    ))}
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>{type4[0].Name}</Typography>
                    </ExpansionPanelSummary>
                    {test4.map((test, index) => (
                        <ul>
                            <li><Typography>{test.Name}</Typography></li>
                        </ul>
                    ))}
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>{type5[0].Name}</Typography>
                    </ExpansionPanelSummary>
                    {test5.map((test, index) => (
                        <ul>
                            <li><Typography>{test.Name}</Typography></li>
                        </ul>
                    ))}
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>{type6[0].Name}</Typography>
                    </ExpansionPanelSummary>
                    {test6.map((test, index) => (
                        <ul>
                            <li><Typography>{test.Name}</Typography></li>
                        </ul>
                    ))}
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>{type7[0].Name}</Typography>

                    </ExpansionPanelSummary>
                    {test7.map((test, index) => (
                        <ul>
                            <li><Typography>{test.Name}</Typography></li>
                        </ul>
                    ))}
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <ExpansionPanelSummary

                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>{type8[0].Name}</Typography>
                    </ExpansionPanelSummary>
                    {test8.map((test, index) => (
                        <ul>
                            <li><Typography>{test.Name}</Typography></li>
                        </ul>
                    ))}
                </ExpansionPanel>
            </div>
        </div>
    )
}

export default Laboratory;