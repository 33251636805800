import React from 'react';
import Medicine from './medicine';
import Class from './medicines.module.css';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const medicines = (props) => {

  return (
    <div className={Class.container}>
      <div className={Class.inputGroup}>
        <TextField
          placeholder="Buscar..."
          variant="outlined"
          onChange={props.handleChange}
          className={Class.searchBar}
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon color="secondary" /></InputAdornment>,
          }}
        />
      </div>
      <div className={Class.spacingDiv}/>
      <Grid container className={Class.grid} spacing={5}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={5}>
            {props.currentMedicines.map((medicine, index) => (
              <Grid key={index} item>
                <Medicine 
                  medicine={medicine} 
                  deleteMedicine = {props.deleteMedicine}
                  saveMedicine = {props.saveMedicine}
                  authenticated = {props.authenticated}
                  loadDiscountList = {props.loadDiscountList}
                  discountList = {props.discountList}
                  exchangeRate = {props.exchangeRate}
                  showNameMissing = {props.showNameMissing}
                  showDescriptionMissing = {props.showDescriptionMissing}
                  closeNameMissingMessage = {props.closeNameMissingMessage}
                  closeDescriptionMissingMessage = {props.closeDescriptionMissingMessage}
                  />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default medicines;