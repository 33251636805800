import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Class from './exchangeRate.module.css';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ExchangeRate = (props) => {

    const[exchangeRate, setExchangeRate] = useState(null);

    const changedExchangeRate = (e) => {
        setExchangeRate(e.target.value);
    }

    const saveExchangeRate = () =>{
        props.saveExchangeRate(exchangeRate);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.closeSuccessMessage();
        window.location.reload(true);
    };

    return (
        <div>
            <div className={Class.textExchangeRate}>Tasa de cambio</div>
            <div className={Class.container}>
                <TextField
                    label="Tasa de cambio"
                    variant="outlined"
                    className={Class.textField}
                    onChange={changedExchangeRate}
                    type="number"
                />
                <Button className={Class.button} variant="contained" onClick={saveExchangeRate} color="secondary">Actualizar</Button>
            </div>
            <Snackbar open={props.showSuccessMessage} anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose}  severity="success">
                La tasa de cambio se actualizó correctamente!
            </Alert>
            </Snackbar>
        </div>
    )
}

export default ExchangeRate